import React, { useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { Button, IconButton } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { styled } from '@mui/material/styles';
import CircularIndeterminate from '../../../components/loading/Loading';
import axios from 'axios';
import { toast } from 'react-toastify';  // Assuming you are using react-toastify for notifications
import { storeimages,deleteImage } from '../../../postApi/Axios';
import { useSelector } from 'react-redux';
import HighlightOffTwoToneIcon from '@mui/icons-material/HighlightOffTwoTone';
import { useGetRobotsForAdminQuery,useGetPiecesRechangeForAdminQuery } from '../../../store/ApiSlice';



const getColor = (props) => {
    if (props.isDragAccept) {
        return '#00e676';
    }
    if (props.isDragReject) {
        return '#ff1744';
    }
    if (props.isDragActive) {
        return '#2196f3';
    }
    return '#eeeeee';
}

const StyledDropzone = styled('div')(
    ({ theme, ...props }) => ({
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px',
        borderWidth: '2px',
        borderRadius: '2px',
        borderColor: getColor(props),
        borderStyle: 'dashed',
        backgroundColor: '#fafafa',
        color: '#bdbdbd',
        outline: 'none',
        transition: 'border .24s ease-in-out',
        cursor: 'pointer',
        minHeight: '150px',
        justifyContent: 'center',
    })
);

export default function ManageImages({ productId, type }) {
    const adminToken = useSelector(state=>state.Auth.adminToken)
    const {data:robots,isLoading:robotLoading,refetch:refetchRobots}=useGetRobotsForAdminQuery(adminToken)
    const {data:piecesRechange,isLoading:piecesRechangeLoading,refetch:refetchPiecesRechange}=useGetPiecesRechangeForAdminQuery(adminToken)
  
    const [files, setFiles] = useState([]);
    const [filePreviews, setFilePreviews] = useState([]);
    const [loading, setloading] = useState(false);
    
    let images = [];
    let variant;


    if (type === 'robots') {
        // Loop through all robots to find the variant
        robots.data.forEach(robot => {
            const foundVariant = robot.variants.find(variant => variant.id === productId);
            if (foundVariant) {
                variant = foundVariant;
            }
        });
    }else{
        piecesRechange.data.forEach(pieceRechange => {
            const foundVariant = pieceRechange.types.find(type => type.id === productId);
            if (foundVariant) {
                variant = foundVariant;
            }
        });
    }

    if (variant && variant.images) {
        images = variant.images; // Assuming images is an array of image objects
    }




    const REACT_APP_CLOUDINARY_CLOUD_NAME = 'dhre3deqz'
    const REACT_APP_CLOUDINARY_UPLOAD_PRESET = 'damay_co'

    const onDrop = useCallback((acceptedFiles) => {
        setFiles(acceptedFiles);
        const previews = acceptedFiles.map(file =>
            Object.assign(file, {
                preview: URL.createObjectURL(file)
            })
        );
        setFilePreviews(previews);
    }, []);

    const uploadImages = async () => {
        setloading(true);  // Start loading before the upload process
        try {
            const responses = await Promise.all(files.map(file => {
                const formData = new FormData();
                formData.append('file', file);
                formData.append('upload_preset', REACT_APP_CLOUDINARY_UPLOAD_PRESET);
        
                const uploadUrl = `https://api.cloudinary.com/v1_1/${REACT_APP_CLOUDINARY_CLOUD_NAME}/upload`;
                return axios.post(uploadUrl, formData, {
                    headers: { 'Content-Type': 'multipart/form-data' }
                }).then(response => response.data.secure_url);
            }));
            // After all files are uploaded, send the URLs to your backend
            const storeImageResponses = await Promise.all(responses.map(imageUrl => {
                // Store each image URL in the database
                return storeimages(adminToken, type, productId, imageUrl);
            }));
        
            storeImageResponses.forEach(resp => {
                if(type==='robots'){
                    refetchRobots()
                }else{
                    refetchPiecesRechange()
                }
                toast.success(`Image a ete enregistrer`);
            });
        
            setFiles([]);  // Clear the files after successful upload
            setFilePreviews([]);  // Clear the previews as well
        } catch (err) {
            console.error('Error uploading or storing images:', err);
            toast.error('Failed to upload or store images');
        } finally {
            setFilePreviews([])
            setloading(false);  // Stop loading irrespective of the outcome
        }
    };
    
    

    const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
        onDrop,
        accept: 'image/*'
    });

    const handleDeleteImage = async (imageId) => {
        setloading(true);
        try {
            const response = await deleteImage(adminToken,type,imageId);
            if(response.status ===200){
                toast.success('Image deleted successfully!');
                if(type==='robots'){
                    refetchRobots()
                }else{
                    refetchPiecesRechange()
                }            }
        } catch (error) {
            console.error('Error deleting image:', error);
            toast.error('Failed to delete image');
        } finally {
            setloading(false);
        }
    };

    if(robotLoading && piecesRechangeLoading){
        return (<CircularIndeterminate />)
    }
    return (
        <>
            {loading && <CircularIndeterminate />}
            <div className='manageImages'>
                <h2 className='section_title my-3' style={{ fontSize: '22px' }}>Gestion des images</h2>
                <div className='imagesstored'>
                <h2 style={{ color: '#7f7e7e', fontSize: '22px' }}>Photos existantes</h2>
                <div className='d-flex my-3 '>
                {images&& images.map(item=>(
                    <div className='position-relative' key={item.id}>
                        <img src={item.image_url} alt="Existing Product" />
                        <IconButton onClick={() => handleDeleteImage(item.id)} className='removeimgbtn' >
                            <HighlightOffTwoToneIcon sx={{ fontSize:33,color:'#ff7070' }} />
                        </IconButton>
                    </div>
                ))}
                </div>
            </div>
            <div className='line mb-2'></div>

                <StyledDropzone {...getRootProps({ isDragActive, isDragAccept, isDragReject })}>
                    <input {...getInputProps()} />
                    <CloudUploadIcon sx={{ fontSize: 50 }} />
                    <p>Drag drop some files here, or click to select files</p>
                </StyledDropzone>
                {filePreviews.length > 0 && (
                    <div className='previewImages'>
                        {filePreviews.map(file => (
                            <img key={file.name} src={file.preview} alt={file.name} style={{ width: 100, height: 100, margin: 10 }} />
                        ))}
                    </div>
                )}
                <Button variant="contained" sx={{ marginTop: '20px' }} onClick={uploadImages}>
                    Upload
                </Button>
            </div>
        </>
    );
}
